// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'local',
  production: false,
  api: {
    host: 'http://localhost:8000'
  },
  reCaptchaKey: '6Lcq2fwZAAAAAErVQQ894xCCz4g84QdWxyh1P8ve',
  //reCaptchaKey: '6Ld-7t0ZAAAAABd31X9lRwpIFeuB_BYwI2Q3dCKO',
  xitiSiteId: 615327,
};
console.log('env...');

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
